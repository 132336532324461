import * as React from "react"
import {
	Flex,
	ImageViewer,
	Text,
	ProductVariantLink,
	Select,
	ProductLabels
} from "@focus-nordic/ui-components"
import { SITE_MAX_WIDTH, PRODUCT_ANCHOR_LIST_HEIGHT } from "../../constants"
import {
	ProductOverviewBlock as IProductOverviewBlock,
	BlockType,
	CustomerTypeEnum
} from "../../@types/graphql.generated"
import { ProductMainInfo } from "./ProductMainInfo"
import { translationNamespace, useLocales } from "../../hooks/useLocales"
import { dataToProps } from "./utils"
import { useHistory } from "react-router-dom"
import { AddToCartButton } from "../AddToCartButton"
import { useProductViewTracking } from "./hooks/useProductViewTracking"
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn"

const ProductOverviewBlock: React.FC<IProductOverviewBlock> = props => {
	const history = useHistory()
	const locales = useLocales(`Blocks_${BlockType.Productoverviewblock}`, [
		"variants"
	])
	const isLoggedIn = useIsLoggedIn()

	useProductViewTracking(
		props.product,
		isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C
	)

	const productLabelLocales = useLocales(translationNamespace.shared("ProductLabel"), [
		"productLabelCampaign",
		"productLabelComingSoon",
		"productLabelKit",
		"productLabelNew",
		"productLabelOutlet",
		"productLabelCampaignBlackFriday",
		"productLabelWarrantyPlural"
    ])

	const cartLocales = useLocales(translationNamespace.shared("Cart"), [
		"cartProductNoMoreItemsMessage"
	])

	return (
		<Flex
			mt={{ _: 0, m: 4 }}
			mb={{ _: 2, m: 6 }}
			w={1}
			id={props.id}
			flexDirection="column"
		>
			<Flex
				w={1}
				px={{ _: 0, m: 8 }}
				flexDirection={{ _: "column", l: "row" }}
				maxw={SITE_MAX_WIDTH}
				style={{ margin: "0 auto" }}
			>
				{props.product.images && (
					<Flex
						display={{ _: "block", l: "flex" }}
						flexDirection="column"
						w={1}
						maxw={{ l: 0.6 }}
						mr={{ _: 0, l: 4 }}
					>
						<ImageViewer 
							images={props.product.images}
							label={props.product.label}
							warranty={props.product.warranty}
							locales={{
								productLabel: {
									[ProductLabels.CAMPAIGN]: productLabelLocales.productLabelCampaign,
									[ProductLabels.COMINGSOON]: productLabelLocales.productLabelComingSoon,
									[ProductLabels.KIT]: productLabelLocales.productLabelKit,
									[ProductLabels.NEW]: productLabelLocales.productLabelNew,
									[ProductLabels.OUTLET]: productLabelLocales.productLabelOutlet,
									[ProductLabels.CAMPAIGNBLACKFRIDAY]: productLabelLocales.productLabelCampaignBlackFriday,
									productLabelWarrantyPlural: productLabelLocales.productLabelWarrantyPlural
								}
							}}
						/>
					</Flex>
				)}
				<Flex flexDirection="column" pt={{ _: 3, l: 2 }} px={{ _: 2, m: 0 }}>
					<ProductMainInfo {...props} />
					{props.product.variants && props.product.variants.length > 0 && (
						<Flex flexDirection="column" my={2}>
							<Flex w={1} justifyContent="space-between">
								<Text
									fontSize={1.75}
									fontWeight="bold"
									textColor="black"
									mb={1}
								>
									{locales.variants}
								</Text>
								{props.variantsAnchor && (
									<a
										href={props.variantsAnchor.url}
										data-target-offset={PRODUCT_ANCHOR_LIST_HEIGHT}
									>
										<Text fontSize={1.75} mb={1} textColor="blue-6">
											{props.variantsAnchor.title}
										</Text>
									</a>
								)}
							</Flex>
							<Flex w={1} flexDirection="row" flexWrap="wrap">
								{props.product.variants.length < 4 ? (
									props.product.variants.map(
										(product, i) =>
											product.name && (
												<Flex mr={1} mb={1}>
													<ProductVariantLink
														to={product.link.url}
														label={product.name}
														image={
															product.mainImage ? (
																<img
																	src={product.mainImage.src}
																	alt={product.mainImage.alt}
																/>
															) : null
														}
													/>
												</Flex>
											)
									)
								) : (
									<Select
										id={`variants-select-${props.product.id}`}
										variant="normal"
										defaultValue={history.location.pathname}
										options={dataToProps(props.product.variants)}
										onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
											history.push(e.target?.value)
										}
									/>
								)}
							</Flex>
						</Flex>
					)}
					<Flex w={1} maxw={40}>
						<AddToCartButton
							parentContext="product-overview"
							product={props.product}
							incrementButtonDisabledTooltipText={cartLocales.cartProductNoMoreItemsMessage}
							disableButtonOnMaxAvailabilityReach={!isLoggedIn} 
							size="normal" 
						/>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	)
}

export { ProductOverviewBlock }
