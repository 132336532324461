import React from "react"
import { Flex, Text, Icon, Drawer } from "@focus-nordic/ui-components/dist"
import { CategoryRoot, CategorySelection, CategoryRootItem, FacetType } from "../../@types/graphql.generated"
import { MENU_HEIGHT, Z_INDEX } from "../../constants"
import { FacetValue, FacetsState, getFacetStateKey } from "./hooks"
import { createFacetValueForCategoryRootItem, createFacetValueForCategorySelection } from "./utils"

interface MobileFacetOfCategoriesProps {
    facet: CategoryRoot
    facetsState: FacetsState
    isOpened: boolean
    onClick: (facetValue: FacetValue) => void
    toggleDrawer: (keepOpen?: boolean) => void
}

export const MobileFacetOfCategories: React.FC<MobileFacetOfCategoriesProps> = ({ facet, facetsState, onClick, isOpened, toggleDrawer }) => {
    React.useEffect(() => {
        if (facet.values[0].__typename !== "categorySelection" && (facet.values[0] as CategoryRootItem)?.values.length === 1) {
            toggleDrawer(false)
        }
    }, [facet])

	const isCategoryRootItem = facet.values.some(value => value.__typename === "categoryRootItem")
    const categoryItems = facet.values[0].__typename === "categoryRootItem" ?
        (facet.values as CategoryRootItem[])[0].values :
        facet.values

    const facetValue = createFacetValueForCategoryRootItem(facet, facet.values[0] as CategoryRootItem)

    const renderCategoryItems = (values: CategorySelection[]) => (
        values.map((value, index) => {
            const categoryKey = FacetType.Category.toLowerCase()
            const facetValue = createFacetValueForCategorySelection(facet, value)
            
            const facetSelected = Object.keys(facetsState).length !== 0 && facetsState.category
                ? facetsState[categoryKey].key.toLowerCase() === getFacetStateKey(facetValue).toLowerCase()
                : index === 0

            if (facetSelected) {
                return (
                    <Flex
                        key={value.key}
                        borderBottom
                        as="span"
                        w={1}
                        justifyContent="space-between"
                        py={2.5}
                        px={2}
                    >
                        <Text as="span" fontSize={2.25} textColor="black" mb={0}>{value.label}</Text>
                    </Flex>
                )
            }

            return (
                <Flex
                    key={value.key}
                    borderBottom
                    py={2.5}
                    px={2}
                >
                    <button
                        onClick={() => onClick(facetValue)} 
                        style={{ width: "100%" }}>
                            <Flex justifyContent="space-between" w={1}>
                                <Text as="span" mb={0} fontSize={2.25}>{value.label}</Text>
                                <Icon color="blue-9" icon="chevron-right" />
                            </Flex>
                    </button>
                </Flex>
            )
        })
    )

	return (
        <Flex h={MENU_HEIGHT.mobile} display={{ _: "flex", xl: "none" }}>
            <Drawer
                zIndex={Z_INDEX.ProductRangeFacets}
                isOpened={isOpened}
                onClose={() => toggleDrawer(false)}
            >
                <Drawer.Header>
                    {isCategoryRootItem ? (
                        <Flex flex={1}>
                            <button onClick={() => onClick?.(facetValue)} style={{ width: "100%", color: "green" }}>
                                <Flex w={1} alignItems="center" justifyContent="space-between">
                                    <Flex>
                                        <Icon icon="chevron-left" />
                                    </Flex>
                                    <Flex flex={1} justifyContent="center">
                                        <Text
                                            textColor="black"
                                            fontSize={2.5}
                                            lineHeight={3.5}
                                            fontWeight="bold"
                                            mb={0}
                                        >
                                            {facet.values[0].label}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </button>
                        </Flex>
                    ) : (
                        <Flex justifyContent="center" flex={1}>
                            <Text
                                textColor="black"
                                fontSize={2.5}
                                lineHeight={3.5}
                                fontWeight="bold"
                                mb={0}
                            >
                                {facet.label}
                            </Text>
                        </Flex>
                    )}
                    <Flex>
                        <Drawer.CloseButton />
                    </Flex>
                </Drawer.Header>
                <Drawer.Content>
                    {renderCategoryItems(categoryItems as CategorySelection[])}
                </Drawer.Content>
            </Drawer>
        </Flex>
		
	)
}

