import React from "react"
import { Helmet } from "react-helmet"
import { Flex, Breadcrumbs, BreadcrumbsLink } from "@focus-nordic/ui-components"
import { MAIN_MIN_HEIGHT, SITE_MAX_WIDTH } from "../../constants"
import { useLocales, usePageTitle } from "../../hooks"
import {  Navigation } from "./components"
import { PageRoute } from "./routes"

export interface LayoutProps {
	breadcrumbsLinks?: BreadcrumbsLink[],
	customerName: string,
	routes: PageRoute[],
	useNavigation?: boolean
}

export const Layout: React.FC<LayoutProps> = props => {
	const locales = useLocales("myPages_common", [
		"home",
	])
	const pageTitle = usePageTitle(
		(props.breadcrumbsLinks &&
			props.breadcrumbsLinks[props.breadcrumbsLinks.length - 1].title) ??
			""
	)
	
	return (
		<>
			<Helmet title={pageTitle} />
			{props.breadcrumbsLinks && (
				<Flex backgroundColor="white" w={1} justifyContent="center">
					<Flex flex={1} maxw={SITE_MAX_WIDTH} py={{ _: 1.5 }} px={2}>
						<Breadcrumbs links={[{id: 'home', url:'/', title: locales.home}, ...props.breadcrumbsLinks]} />
					</Flex>
				</Flex>
			)}
			<Flex
				flexDirection="column"
				alignItems="center"
				w={1}
				maxw={SITE_MAX_WIDTH}
				minh={MAIN_MIN_HEIGHT}
				mt={{ _: 2, m: 7 }}
				mb={{_: 9, m: 7}}
				px={{ _: 0, m: 2 }}
				overflow="unset"
			>
				<Flex
					w={1}
					flex={1}
					alignItems="flex-start"
					justifyContent="flex-start"
					flexWrap="wrap"
					flexDirection={{ _: "column-reverse",  xl: "row" }}
				>
					<Flex w={{ _: 1, ...(props.useNavigation && { xl: 9 / 12 }) }} pr={{ _: 0,  xl: 4 }} flexDirection="column" flex={1}>
						{props.children}
					</Flex>
					{props.useNavigation && (
						<Flex w={{ _: 1, xl: 3 / 12 }} mt={{ _: 0, xl: 6 }} mb={{ _: 4, xl: 0 }} flexDirection="column">
							<Navigation 
								customerName={props.customerName}
								routes={props.routes}
							/>
						</Flex>
					)}
				</Flex>
			</Flex>
		</>
	)
}
