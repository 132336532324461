import * as Types from '../../@types/graphql.generated';

import { CommonProductFieldsFragment, ProductRangeFieldsFragment } from '../../operations/product.generated';
import { gql } from '@apollo/client';
import { CommonProductFieldsFragmentDoc, ProductRangeFieldsFragmentDoc } from '../../operations/product.generated';
export type ProductListBlockFieldsFragment = (
  { __typename?: 'productListBlock' }
  & Pick<Types.ProductListBlock, 'id' | 'brandId' | 'preamble' | 'productCategoryId' | 'title' | 'type' | 'categoryName' | 'featuredProductsTitle'>
  & { featuredProducts?: Types.Maybe<Array<(
    { __typename?: 'product' }
    & CommonProductFieldsFragment
  )>>, productRange?: Types.Maybe<(
    { __typename?: 'products' }
    & ProductRangeFieldsFragment
  )> }
);

export const ProductListBlockFieldsFragmentDoc = gql`
    fragment productListBlockFields on productListBlock {
  id
  brandId
  preamble
  productCategoryId
  title
  type
  categoryName
  featuredProductsTitle
  featuredProducts {
    ...commonProductFields
  }
  productRange(productsInput: $productsInput, facetsInput: $facetsInput, paginationInput: $paginationInput, sortInput: $sortInput) {
    ...productRangeFields
  }
}
    ${CommonProductFieldsFragmentDoc}
${ProductRangeFieldsFragmentDoc}`;