import * as Types from '../../@types/graphql.generated';

import { ProductRangeFieldsFragment } from '../../operations/product.generated';
import { gql } from '@apollo/client';
import { ProductRangeFieldsFragmentDoc } from '../../operations/product.generated';
import * as Apollo from '@apollo/client';
export type GetProductRangeQueryVariables = Types.Exact<{
  productsInput?: Types.Maybe<Types.ProductsInput>;
  paginationInput?: Types.Maybe<Types.PaginationInput>;
  facetsInput?: Types.Maybe<Types.ProductsFacetsInput>;
  sortInput?: Types.Maybe<Types.SortInput>;
  searchInput?: Types.Maybe<Types.SearchInput>;
}>;


export type GetProductRangeQuery = (
  { __typename?: 'Query' }
  & { productRange?: Types.Maybe<(
    { __typename?: 'products' }
    & ProductRangeFieldsFragment
  )> }
);


export const GetProductRangeDocument = gql`
    query GetProductRange($productsInput: productsInput, $paginationInput: paginationInput, $facetsInput: productsFacetsInput, $sortInput: sortInput, $searchInput: searchInput) {
  productRange(productsInput: $productsInput, paginationInput: $paginationInput, facetsInput: $facetsInput, sortInput: $sortInput, searchInput: $searchInput) {
    ...productRangeFields
  }
}
    ${ProductRangeFieldsFragmentDoc}`;

/**
 * __useGetProductRangeQuery__
 *
 * To run a query within a React component, call `useGetProductRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductRangeQuery({
 *   variables: {
 *      productsInput: // value for 'productsInput'
 *      paginationInput: // value for 'paginationInput'
 *      facetsInput: // value for 'facetsInput'
 *      sortInput: // value for 'sortInput'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useGetProductRangeQuery(baseOptions?: Apollo.QueryHookOptions<GetProductRangeQuery, GetProductRangeQueryVariables>) {
        return Apollo.useQuery<GetProductRangeQuery, GetProductRangeQueryVariables>(GetProductRangeDocument, baseOptions);
      }
export function useGetProductRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductRangeQuery, GetProductRangeQueryVariables>) {
          return Apollo.useLazyQuery<GetProductRangeQuery, GetProductRangeQueryVariables>(GetProductRangeDocument, baseOptions);
        }
export type GetProductRangeQueryHookResult = ReturnType<typeof useGetProductRangeQuery>;
export type GetProductRangeLazyQueryHookResult = ReturnType<typeof useGetProductRangeLazyQuery>;
export type GetProductRangeQueryResult = Apollo.QueryResult<GetProductRangeQuery, GetProductRangeQueryVariables>;