import * as Types from '../@types/graphql.generated';

import { AllLinkFieldsFragment } from './link.generated';
import { AllStockStatusFieldsFragment } from './stockStatus.generated';
import { AllImageFieldsFragment } from './image.generated';
import { AllPaginationFieldsFragment } from './pagination.generated';
import { gql } from '@apollo/client';
import { AllLinkFieldsFragmentDoc } from './link.generated';
import { AllStockStatusFieldsFragmentDoc } from './stockStatus.generated';
import { AllImageFieldsFragmentDoc } from './image.generated';
import { AllPaginationFieldsFragmentDoc } from './pagination.generated';
export type CommonProductFieldsFragment = (
  { __typename?: 'product' }
  & Pick<Types.Product, 'id' | 'articleNumber' | 'vendorArticleNumber' | 'ean' | 'name' | 'buyable' | 'packageSize' | 'forcedPackageSize' | 'description' | 'margin' | 'categories' | 'label' | 'warranty' | 'brandName' | 'onDemand' | 'availableItemsCount'>
  & { discount?: Types.Maybe<(
    { __typename?: 'product_DiscountModel' }
    & Pick<Types.Product_DiscountModel, 'startDate' | 'endDate'>
    & { comparisonPrice: (
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
    ), discountedPrice: (
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
    ) }
  )>, rsp?: Types.Maybe<(
    { __typename?: 'priceInfo' }
    & Pick<Types.PriceInfo, 'netPrice' | 'currency'>
  )>, fee?: Types.Maybe<(
    { __typename?: 'priceInfo' }
    & Pick<Types.PriceInfo, 'netPrice' | 'currency'>
  )>, price?: Types.Maybe<(
    { __typename?: 'priceInfo' }
    & Pick<Types.PriceInfo, 'netPrice' | 'currency'>
  )>, link: (
    { __typename?: 'link' }
    & AllLinkFieldsFragment
  ), stockStatus?: Types.Maybe<(
    { __typename?: 'stockStatus' }
    & AllStockStatusFieldsFragment
  )>, mainImageSmall?: Types.Maybe<(
    { __typename?: 'image' }
    & AllImageFieldsFragment
  )> }
);

export type ProductRangeFieldsFragment = (
  { __typename?: 'products' }
  & Pick<Types.Products, 'id' | 'brandId' | 'campaignId' | 'categoryId' | 'defaultCardLayout'>
  & { currentSort: (
    { __typename?: 'productSortOptions' }
    & Pick<Types.ProductSortOptions, 'label' | 'value'>
  ), sortOptions: Array<(
    { __typename?: 'productSortOptions' }
    & Pick<Types.ProductSortOptions, 'label' | 'value'>
  )>, facets?: Types.Maybe<Array<(
    { __typename?: 'categoryRoot' }
    & Pick<Types.CategoryRoot, 'key' | 'label' | 'type'>
    & { values: Array<(
      { __typename?: 'categorySelection' }
      & Pick<Types.CategorySelection, 'count' | 'key' | 'label'>
    ) | (
      { __typename?: 'categoryRootItem' }
      & Pick<Types.CategoryRootItem, 'key' | 'label'>
      & { values: Array<(
        { __typename?: 'categorySelection' }
        & Pick<Types.CategorySelection, 'count' | 'key' | 'label'>
      )> }
    )> }
  ) | (
    { __typename?: 'range' }
    & Pick<Types.Range, 'key' | 'label' | 'max' | 'min' | 'type'>
  ) | (
    { __typename?: 'selection' }
    & Pick<Types.Selection, 'key' | 'label' | 'type' | 'isBrandFacet'>
    & { values: Array<(
      { __typename?: 'selectionValue' }
      & Pick<Types.SelectionValue, 'count' | 'value'>
    )> }
  )>>, pagination: (
    { __typename?: 'pagination' }
    & AllPaginationFieldsFragment
  ), products?: Types.Maybe<Array<(
    { __typename?: 'product' }
    & CommonProductFieldsFragment
  )>> }
);

export const CommonProductFieldsFragmentDoc = gql`
    fragment commonProductFields on product {
  id
  articleNumber
  vendorArticleNumber
  ean
  name
  buyable
  packageSize
  forcedPackageSize
  description
  margin
  categories
  label
  warranty
  discount {
    comparisonPrice {
      currency
      netPrice
    }
    discountedPrice {
      currency
      netPrice
    }
    startDate
    endDate
  }
  rsp {
    netPrice
    currency
  }
  fee {
    netPrice
    currency
  }
  price {
    netPrice
    currency
  }
  link {
    ...allLinkFields
  }
  brandName
  onDemand
  stockStatus {
    ...allStockStatusFields
  }
  mainImageSmall {
    ...allImageFields
  }
  availableItemsCount
}
    ${AllLinkFieldsFragmentDoc}
${AllStockStatusFieldsFragmentDoc}
${AllImageFieldsFragmentDoc}`;
export const ProductRangeFieldsFragmentDoc = gql`
    fragment productRangeFields on products {
  id
  brandId
  campaignId
  categoryId
  defaultCardLayout
  currentSort {
    label
    value
  }
  sortOptions {
    label
    value
  }
  facets {
    ... on categoryRoot {
      key
      label
      type
      values {
        ... on categorySelection {
          count
          key
          label
        }
        ... on categoryRootItem {
          key
          label
          values {
            count
            key
            label
          }
        }
      }
    }
    ... on range {
      key
      label
      max
      min
      type
    }
    ... on selection {
      key
      label
      type
      isBrandFacet
      values {
        count
        value
      }
    }
  }
  pagination {
    ...allPaginationFields
  }
  products {
    ...commonProductFields
  }
}
    ${AllPaginationFieldsFragmentDoc}
${CommonProductFieldsFragmentDoc}`;