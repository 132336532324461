import * as React from "react"
import styled from "styled-components"
import {
	Flex,
	Drawer,
	Select,
	Text,
	Button,
	Spinner,
	Icon
} from "@focus-nordic/ui-components"
import debounce from "lodash/debounce"
import {
	QueryProductRangeArgs,
	Product,
	BlockType,
	CardLayout,
	CategoryRoot,
	FacetType,
} from "../../@types/graphql.generated"
import useDeepCompareEffect from "use-deep-compare-effect"
import { ProductCardList, ProcuctListToggle } from ".."
import { useLocales, getNamespacedTranslationKey } from "../../hooks"
import * as utils from "./utils"
import { Facets } from "./Facets"
import { MobileFacetOfCategories } from "./MobileFacetOfCategories";
import {
	GetProductRangeQuery,
	useGetProductRangeLazyQuery
} from "./operations.generated"
import { ProductRangeFieldsFragment } from '../../operations/product.generated';
import {
	facetsStateToFacetsInput,
	QueryProductRangeArgsToProductQueryString,
	getProductExportUrls,
	ProductExportUrls
} from "./utils"
import { useFacetsReducer, FacetValue } from "./hooks"
import { Chips } from "./Chips"
import { colors } from "@focus-nordic/ui-components"
import { Z_INDEX, SITE_MAX_WIDTH } from "../../constants"
import { ProductListBlock } from "../../@types/graphql.generated"
import { Pagination } from "../Pagination"
import { useLocation, useHistory } from "react-router"
import { LocalesContext } from "../../context/LocalesContext"
import { trackPriceExport } from "./utils"
import { useIsLoggedIn } from "../../hooks"
import { UiContext } from "../../context"

type ProductRangeFieldsFragmentType = ProductRangeFieldsFragment | undefined

export interface ProductRangeProps
	extends Omit<GetProductRangeQuery, "__typename">,
		Partial<
			Pick<
				ProductListBlock,
				"title" | "productCategoryId" | "brandId" | "featuredProducts" | "featuredProductsTitle"
			>
		> {
	queryProductRangeArgs?: QueryProductRangeArgs
	hasPriceListExport?: boolean
}

// Export links for product data
const ExportLink = styled.a`
	font-size: inherit;
	line-height: inherit;
	text-decoration: underline;

	&:hover {
		color: ${colors["blue-3"]};
	}
`

const ProductRange: React.FC<ProductRangeProps> = props => {
	// // Todo: save user inputs in query string
	// let queryStringValues = useBlockQueryParams()

	const {
		activeProductRangeCardLayout,
		setActiveProductRangeCardLayout
	} = React.useContext(UiContext)

	const locales = useLocales(BlockType.Productlistblock, [
		"sort",
		"showAs",
		"filters",
		"default",
		"lowestPrice",
		"highestPrice",
		"showProducts",
		"all",
		"exportPriceList"
	])

	const { translations } = React.useContext(LocalesContext)
	const history = useHistory()
	const location = useLocation()
	const [isDrawerOpened, setIsDrawerOpened] = React.useState(false)
	const [isInitialRender, setIsInitialRender] = React.useState(true)
	const [isMobileCategoriesDrawerOpened, setMobileCategoriesDrawerOpened] = React.useState(false)
	const [facetsState, facetsStateDispatch] = useFacetsReducer({
		...utils.productQueryStringToFacetState(location.search)
	})
	const isLoadMoreRequest = React.useRef(false)
	const [productRange, setProductRange] = React.useState<ProductRangeFieldsFragmentType>(props.productRange)

	const [productQueryInput, setProductQueryInput] = React.useState<
		QueryProductRangeArgs
	>({
		...(props.queryProductRangeArgs ?? {}),
		...utils.queryStringToProductRangeArgs(location.search),
		...(Object.keys(facetsState).length > 0
			? { facetsInput: facetsStateToFacetsInput(facetsState) }
			: {})
	})

	const [getProductRange, { loading }] = useGetProductRangeLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (result) => {
			const incomingRange = result?.productRange as ProductRangeFieldsFragment;

			if (isLoadMoreRequest.current) {
				setProductRange((existingRange: ProductRangeFieldsFragmentType) => ({
					...incomingRange,
					products: [...(existingRange?.products ?? []), ...(incomingRange?.products ?? [])]
				}));
			} else {
				setProductRange(result?.productRange)
			}
			isLoadMoreRequest.current = false;
		},
	})

	const toggleDrawerOpened = () => setIsDrawerOpened(prev => !prev)

	const hasFacets = Boolean(productRange?.facets?.length)

	const onSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const target = e.target

		setProductQueryInput(prev => ({
			...prev,
			sortInput: {
				sortValue: target.value
			}
		}))
	}

	React.useEffect(() => {
		const facetsInput = facetsStateToFacetsInput(facetsState)
		setProductQueryInput(prev => {
			const { facetsInput: omitFacetsInput, ...rest } = prev
			return {
				...rest,
				...(Object.keys(facetsInput).length > 0 ? { facetsInput } : {})
			}
		})
	}, [facetsState])

	const debouncedFetchProduct = React.useRef(
		debounce(getProductRange, 750, { leading: false })
	)

	// Action Creators
	const addFacet = (facetValue: FacetValue) => {
		facetsStateDispatch({
			type: "ADD_FACET",
			payload: facetValue
		})
	}

	const removeFacet = (facetValue: FacetValue) => {
		facetsStateDispatch({
			type: "REMOVE_FACET",
			payload: facetValue
		})
	}

	const clearFacets = () => {
		facetsStateDispatch({
			type: "CLEAR_FACETS"
		})
	}

	const onCategoryChange = (facetValue: FacetValue) => {
		facetsStateDispatch({
			type: "UPDATE_FACET",
			payload: facetValue
		})
	}

	//TODO: double request needs to be optimazed
	const unselectBrand = () => {
		const queryString = Object.keys(productQueryInput).length > 0
			? `${QueryProductRangeArgsToProductQueryString(productQueryInput)}` : ""

		history.push({
			pathname: location.pathname,
			search: queryString ? `?${queryString}&brandId=` : "?brandId="
		})
	}

	const toggleMobileCategoriesDrawer = (keepOpen?: boolean) => {
		if (keepOpen === undefined) {
			setMobileCategoriesDrawerOpened(prev => !prev)
		} else {
			setMobileCategoriesDrawerOpened(keepOpen)
		}
	}

	// update query params based on selected filters
	useDeepCompareEffect(() => {
		if (!isInitialRender) {
			window.history.replaceState(
				{},
				document.title,
				Object.keys(productQueryInput).length > 0
					? `?${QueryProductRangeArgsToProductQueryString(productQueryInput)}`
					: location.pathname
			)
			debouncedFetchProduct.current({
				variables: utils.productRangeArgs(productQueryInput, productRange).facet
			})
		}
		setIsInitialRender(false)
	}, [productQueryInput])

	const [
		productExportUrls,
		setProductExportUrls
	] = React.useState<ProductExportUrls | null>(null)

	const isLoggedIn = useIsLoggedIn()

	React.useEffect(() => {
		// Set URLs for product export if user is logged in and is authenticated
		const productQueryString =
			Object.keys(productQueryInput).length > 0
				? `?${QueryProductRangeArgsToProductQueryString(productQueryInput)}`
				: ""
		setProductExportUrls(getProductExportUrls(productQueryString, productRange))
	}, [productQueryInput, productRange])
	
	const hasCategoriesFacet = Boolean(productRange?.facets?.some(facet => facet.__typename === "categoryRoot"))

	const categoryFacet = Object.values(facetsState).find(facet => facet.type === FacetType.Category)

	const productsListTitle = React.useMemo(() => {
		if (productRange && productRange.facets && productRange.facets[0]) {
		  const facet = productRange.facets[0]
		  
		  if (facet.__typename === "categoryRoot") {
			if (categoryFacet && categoryFacet.value) {
			  return categoryFacet.value
			}
			
			if (facet.values[0] && facet.values[0].__typename === "categoryRootItem") {
			  const categoryRootItem = facet.values[0]
			  
			  if (categoryRootItem.values[0] && categoryRootItem.values[0].key) {
				const key = categoryRootItem.values[0].key
				return key.substring(key.lastIndexOf("|") + 1)
			  }
			}
			
			if (facet.values[0] && facet.values[0].label) {
			  return facet.values[0].label
			}
		  }
		}
		
		return props.title || locales.all
	}, [productRange, categoryFacet, props.title, locales.all])

	const filteredFacetsState = Object.entries(facetsState).reduce((acc, facet) => {
		if (facet[1].type !== FacetType.Category) {
			acc[facet[0]] = facet[1]
 		}
		return acc
	}, {})

	return (
		<Flex flexDirection="column" w={1} alignItems="center">
			<Flex flexDirection="column" w={1} maxw={SITE_MAX_WIDTH}>
				<Flex w={1} alignItems="flexStart" key={productRange?.categoryId}>
					{hasFacets && (
						<Flex
							w={{ xl: 1 / 4 }}
							pl={hasFacets ? { xl: 2 } : undefined}
							flexDirection="column"
						>
							<Drawer
								zIndex={Z_INDEX.ProductRangeFacets}
								minBreakpoint="xl"
								isOpened={isDrawerOpened}
								onClose={toggleDrawerOpened}
							>
								<Drawer.Header>
									<Drawer.CloseButton />
								</Drawer.Header>
								<Drawer.Content>
									{productRange?.facets && (
										<Facets
											facets={productRange?.facets}
											facetsState={facetsState}
											onAddFacet={addFacet}
											onRemoveFacet={removeFacet}
											disabled={loading}
											onClick={onCategoryChange}
											brandId={productRange.brandId}
											onBrandUnselect={unselectBrand}
											productCategoryId={productRange?.categoryId}
										/>
									)}
								</Drawer.Content>
								{typeof productRange?.pagination?.count !== "undefined" ? (
									<Flex
										display={{ _: "flex", xl: "none" }}
										w={1}
										px={2}
										py={3}
										backgroundColor="white"
									>
										<Button onClick={toggleDrawerOpened} stretch>
											{locales.showProducts} ({productRange.pagination.count})
										</Button>
									</Flex>
								) : null}
							</Drawer>
						</Flex>
					)}
					{hasCategoriesFacet && productRange?.facets && (
						<MobileFacetOfCategories
							facet={productRange.facets?.[0] as CategoryRoot}
							facetsState={facetsState}
							onClick={onCategoryChange}
							isOpened={isMobileCategoriesDrawerOpened}
							toggleDrawer={toggleMobileCategoriesDrawer}
						/>
					)}
					<Flex
						w={hasFacets ? { _: 1, xl: 3 / 4 } : 1}
						pl={{ xl: 1 }}
						flexDirection="column"
					>
						{props.featuredProducts && props.featuredProducts.length > 0 ? (
							<Flex flexDirection="column">
								<Text whiteSpace="nowrap" variant="headline-5" mb={0} px={{ _: 2, xl: 5 }} pr={{ xl: 4 }}>
									{props.featuredProductsTitle}
								</Text>
								<Flex
									pt={2}
									pr={{ _: 1, xl: 2 }}
									pl={{ _: 1, xl: 3 }}
									overflow={{ _: "auto", m: "hidden" }}
								>
									<ProductCardList
										cardLayout={CardLayout.Large}
										products={props.featuredProducts}
										columnWidth={{ m: 1 / 4 }}
										columnFlex={{ _: "0 0 166px", m: "none"}}
										withoutBottomSeparator={true}
										simpleView={true}
									/>
								</Flex>
								<Flex 
									borderBottom 
									ml={{ _: 2, xl: 5 }} 
									mr={{ _: 2, xl: 4 }} 
									mt={{ _: 2, xl: 4 }} 
									mb={{ _: 4, xl: 3 }}
								>
								</Flex>
							</Flex>
						) : null}
						<Flex display={{ _: "flex", xl: "none" }} alignItems="center" justifyContent="space-between" backgroundColor="blue-1" p={2}>
							<Flex flex={2} alignItems="end">
								<Flex flexWrap="wrap">
									{!loading ? (
										<Text variant="headline-5" mr={1} mb={0}>{productsListTitle}:
										 	<Text
												whiteSpace="nowrap"
												as="span"
												textColor="grey-4"
												fontWeight="normal"
												fontSize={{ _: 2, m: 2.25, l: 3 }}
												lineHeight={{ _: 2.5, l: 4 }}
												ml={1}
												mb={0}
											>
												{productRange?.pagination?.count}
											</Text>
										</Text>
									) : (
										<Spinner
											strokeColor={colors["grey-4"]}
											size={2.5}
										/>
									)}
								</Flex>
							</Flex>
							<Flex flex={1} ml={1} justifyContent="flex-end">
								<Button
									variant="transparent"
									icon="chevron-right"
									iconPosition="right"
									fontWeight="normal"
									p={0}
									onClick={() => toggleMobileCategoriesDrawer(true)}
								>
									Categories
								</Button>
							</Flex>
						</Flex>
						<Flex
							flexDirection="column"
							p={2}
							px={{ _: 2, xl: 5 }}
							pr={{ xl: 4 }}
						>
							<Flex
								flexWrap="wrap"
								alignItems="flex-start"
								justifyContent="space-between"
								mb={2}
							>
								<Flex minw={0} flex={2} mb={1} alignItems="center" display={{ _: "none", xl: "flex"}}>
									{!loading ? (
										<>
											<Text whiteSpace="nowrap" variant="headline-5" mr={1} mb={0}>
												{productsListTitle}
											</Text>
											<Text
												whiteSpace="nowrap"
												variant="headline-5"
												textColor="grey-4"
												fontWeight="normal"
												mr={2}
												mb={0}
											>
												{productRange?.pagination?.count}
											</Text>
										</>
									) : (
										<Spinner
											strokeColor={colors["grey-4"]}
											size={{ _: 2.5, m: 3 }}
										/>
									)}
								</Flex>
								<Flex
									flex={1}
									alignItems="center"
									justifyContent="space-between"
								>
									{hasFacets && (
										<Flex
											flex={{ _: 1, s: "none" }}
											order={1}
											display={{ _: "flex", xl: "none" }}
											mr={{ s: 2 }}
										>
											<Button
												border
												variant="grey"
												icon="filter"
												onClick={toggleDrawerOpened}
												mr={{ s: 1 }}
												stretch={{ _: true, s: false }}
											>
												{locales.filters}
											</Button>
										</Flex>
									)}

									{productRange?.sortOptions && (
										<Flex
											flex={{ _: 1, s: "none" }}
											order={{ _: 3, s: 1 }}
											mr={{ s: 2 }}
											alignItems="center"
										>
											<Text
												whiteSpace="nowrap"
												display={{ _: "none", s: "block" }}
												fontWeight="normal"
												mr={1}
												mb={0}
											>
												{locales.sort}:
											</Text>
											<Flex flex={1} minw={{ s: 20 }}>
												<Select
													variant="small"
													onChange={onSortChange}
													disabled={loading}
													defaultValue={productQueryInput.sortInput?.sortValue}
													options={productRange.sortOptions.map(option => {
														const translationKey = getNamespacedTranslationKey(
															BlockType.Productlistblock,
															option.label
														)
														return {
															...option,
															label:
																translations[translationKey] || translationKey
														}
													})}
												/>
											</Flex>
										</Flex>
									)}
									<Flex
										mx={{ _: 1, s: 0 }}
										order={{ _: 2, s: 1 }}
										alignItems="center"
									>
										<Text
											whiteSpace="nowrap"
											display={{ _: "none", s: "block" }}
											fontWeight="normal"
											mb={0}
											mr={1}
										>
											{locales.showAs}
										</Text>
										<ProcuctListToggle
											onToggle={cardLayout =>
												setActiveProductRangeCardLayout(cardLayout)
											}
											currentListType={activeProductRangeCardLayout}
										/>
									</Flex>
								</Flex>
							</Flex>
							{Object.keys(filteredFacetsState).length > 0 ? (
								<Chips
									facetsState={filteredFacetsState}
									onRemoveFacet={removeFacet}
									onClearFacets={clearFacets}
									disabled={loading}
								/>
							) : null}
							{isLoggedIn && productExportUrls && props.hasPriceListExport && (
								<Flex justifyContent="flex-end">
									<Icon
										icon="download"
										color="blue-3"
										size={2.25}
										mt={0.25}
										mr={1}
									/>
									<Text
										fontSize={1.75}
										lineHeight={2.5}
										textColor="grey-4"
										mb={0}
									>
										{`${locales.exportPriceList}: `}
										{productExportUrls && (
											<>
												<ExportLink
													href={productExportUrls.csv}
													target="_blank"
													rel="noreferrer nofollow"
													onClick={() =>
														trackPriceExport(
															"CSV",
															props.title || location.pathname
														)
													}
													download
												>
													csv
												</ExportLink>
												{` / `}
												<ExportLink
													href={productExportUrls.xlsx}
													target="_blank"
													rel="noreferrer nofollow"
													onClick={() =>
														trackPriceExport(
															"XLSX",
															props.title || location.pathname
														)
													}
													download
												>
													excel
												</ExportLink>
											</>
										)}
									</Text>
								</Flex>
							)}
						</Flex>
						<Flex
							flexWrap="wrap"
							pr={
								activeProductRangeCardLayout === CardLayout.Large
									? { _: 1, xl: 0 }
									: { _: 2, xl: 2 }
							}
							pl={
								activeProductRangeCardLayout === CardLayout.Large
									? { _: 1, xl: 1 }
									: { _: 2, xl: 3 }
							}
						>
							{productRange?.products && productRange.products.length > 0 && (
								<>
									<ProductCardList
										cardLayout={activeProductRangeCardLayout}
										products={productRange.products as Product[]}
										pagination={productRange.pagination}
										columnWidth={
											hasFacets
												? { _: 1 / 2, m: 1 / 3, l: 1 / 4, xl: 1 / 3 }
												: undefined
										}
									/>
									<Pagination
										pagination={productRange.pagination}
										fetchMore={() => {
											isLoadMoreRequest.current = true;
											getProductRange({
												variables: utils.productRangeArgs(
													productQueryInput,
													productRange
												).pagination,
											})
										}}
										disabled={loading}
									/>
								</>
							)}
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	)
}

ProductRange.defaultProps = {
	hasPriceListExport: true
}

export { ProductRange }
