import React from "react"
import { Helmet } from "react-helmet"
import { Switch, Route, Redirect } from "react-router-dom"
import { Flex, BreadcrumbsLink } from "@focus-nordic/ui-components"
import { TITLE_MY_PAGES, MAIN_MIN_HEIGHT } from "../../constants"
import { usePageTitle } from "../../hooks"
import { MY_PAGES_ROUTE } from "@focus-nordic/www-common/constants"
import { Loader } from "../Loader"
import { useGetCheckoutTranslationsQuery } from "./operations.generated"
import { LocalesContext, LocalesContextProvider } from "../../context"
import { translationsArrayToTranslationsRecord } from "../../utils"
import {
	PAGE_ROUTES,
	PAGE_ROUTES_TITLES,
	CUSTOMER_INFORMATION_PAGE_ROUTE,
	getLocaleFilteredRoutes
} from "./routes"
import { useLocales } from "../../hooks/useLocales"
import {
	useGetUserQuery,
	GetUserQueryResult
} from "../../operations/user.generated"

const recursiveRoutes = (
	locales: Record<string, string>,
	routes: typeof PAGE_ROUTES,
	user: GetUserQueryResult,
	path: string = MY_PAGES_ROUTE,
	breadcrumbs: BreadcrumbsLink[] = []
) => {
	return routes.map(pageRoute => {
		const currentPath = `${path}/${pageRoute.path}`
		const breadcrumbsLinks = [
			...breadcrumbs,
			{
				id: pageRoute.path,
				url: currentPath,
				title: locales[pageRoute.titleTranslationKey]
			}
		]

		return (
			<React.Fragment key={currentPath}>
				<Route
					exact
					path={currentPath}
					component={() =>
						React.createElement(pageRoute.component, {
							breadcrumbsLinks,
							customerName: user?.data?.user?.currentCustomer?.name || "",
							routes: PAGE_ROUTES,
							useNavigation: pageRoute.useNavigation
						})
					}
				/>
				{pageRoute.subpages &&
					recursiveRoutes(
						locales,
						pageRoute.subpages,
						user,
						currentPath,
						breadcrumbsLinks
					)}
			</React.Fragment>
		)
	})
}

const Routes = () => {
	const locales = useLocales("myPages", PAGE_ROUTES_TITLES)
	const user = useGetUserQuery()
	const { localeCode } = React.useContext(LocalesContext)
	const localeFilteredRoutes = React.useMemo(() => {
		return getLocaleFilteredRoutes(PAGE_ROUTES, localeCode)
	}, [localeCode])

	return (
		<>
			{user.loading ? null : user.data ? (
				<Switch>
					<Flex flexDirection="column" w={1} alignItems="center">
						{recursiveRoutes(locales, localeFilteredRoutes, user)}
						<Route exact path={MY_PAGES_ROUTE}>
							<Redirect
								to={`${MY_PAGES_ROUTE}/${CUSTOMER_INFORMATION_PAGE_ROUTE.path}`}
							/>
						</Route>
					</Flex>
				</Switch>
			) : (
				<Switch>
					<Route path="*">
						<Redirect to="/" />
					</Route>
				</Switch>
			)}
		</>
	)
}

export const MyPagesPage: React.FC = () => {
	const checkoutTranslations = useGetCheckoutTranslationsQuery()
	const localesContext = React.useContext(LocalesContext)
	const translations = checkoutTranslations.data?.b2BMyPagesTranslations
	const pageTitle = usePageTitle(TITLE_MY_PAGES)

	return (
		<Flex
			w={1}
			h={1}
			flexDirection="column"
			alignItems="center"
			backgroundColor="grey-7"
			minh={MAIN_MIN_HEIGHT}
		>
			{/* override any previous page titles */}
			<Helmet title={pageTitle} />
			{!checkoutTranslations.data ? (
				<Loader minh="100vh" />
			) : (
				<LocalesContextProvider
					{...localesContext}
					// merge checkout translations with previusly fetched translations
					translations={{
						...localesContext.translations,
						...translationsArrayToTranslationsRecord(
							"myPages",
							translations ?? []
						)
					}}
				>
					<Routes />
				</LocalesContextProvider>
			)}
		</Flex>
	)
}
