import React from "react"
import { Blocks, BlockType } from "../../@types/graphql.generated"
import {
	TextEditorBlock,
	CategoryLinksBlock,
	CategoryLinkListBlock,
	ProductListBlock,
	BreadcrumbsBlock,
	HeroBlock,
	AnchorLinkBlock,
	CategoryHeroBlock,
	ArticleIntroBlock,
	InspirationHeroBlock,
	DescriptionListBlock,
	CardPromoBlock,
	MediaAssetsBlock,
	MediaContentBlock,
	MediaGridBlock,
	ProductOverviewBlock,
	RelatedProductsBlock,
	BrandLinkListBlock,
	SearchBlock,
	FullWidthImagePromoBlock,
	CustomerApplicationBlock,
	GalleryBlock,
	FeaturedProductsBlock,
	ProductCategoriesBlock,
	VideoBlock
} from "../"

export interface BlocksResolverProps {
	blocks?: Blocks[]
}

const getBlockTypes = (blockType: BlockType): React.FC<any> =>
	({
		[BlockType.Texteditorblock]: TextEditorBlock,
		[BlockType.Productlistblock]: ProductListBlock,
		[BlockType.Categorylinklistblock]: CategoryLinkListBlock,
		[BlockType.Breadcrumbsblock]: BreadcrumbsBlock,
		[BlockType.Categorylinksblock]: CategoryLinksBlock,
		[BlockType.Heroblock]: HeroBlock,
		[BlockType.Anchorlinkblock]: AnchorLinkBlock,
		[BlockType.Categoryheroblock]: CategoryHeroBlock,
		[BlockType.Productoverviewblock]: ProductOverviewBlock,
		[BlockType.Descriptionlistblock]: DescriptionListBlock,
		[BlockType.Mediaassetsblock]: MediaAssetsBlock,
		[BlockType.Mediagridblock]: MediaGridBlock,
		[BlockType.Relatedproductsblock]: RelatedProductsBlock,
		[BlockType.Inspirationheroblock]: InspirationHeroBlock,
		[BlockType.Articleintroblock]: ArticleIntroBlock,
		[BlockType.Cardpromoblock]: CardPromoBlock,
		[BlockType.Rteblock]: React.Fragment,
		[BlockType.Mediacontentblock]: MediaContentBlock,
		[BlockType.Fullwidthimagepromoblock]: FullWidthImagePromoBlock,
		[BlockType.Brandlinklistblock]: BrandLinkListBlock,
		[BlockType.Searchblock]: SearchBlock,
		[BlockType.Customerapplicationblock]: CustomerApplicationBlock,
		[BlockType.Galleryblock]: GalleryBlock,
		[BlockType.Featuredproductsblock]: FeaturedProductsBlock,
		[BlockType.Productcategoriesblock]: ProductCategoriesBlock,
		[BlockType.Videoblock]: VideoBlock,
		[BlockType.Unknown]: React.Fragment
	}[blockType] || React.Fragment)

const BlockResolver: React.FC<BlocksResolverProps> = props => (
	<>
		{props.blocks &&
			props.blocks.map((block, index) => {
				const Component = getBlockTypes(block.type);
				const { __typename, type, ...filteredProps } = block;
		
				return React.createElement(Component, {
					...(block.type === BlockType.Unknown ? filteredProps : block),
					key: block.id ?? index,
				});
			})}
	</>
)

export { BlockResolver }
