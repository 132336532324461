import React from "react"
import { Helmet } from "react-helmet"
import { useGetCheckoutTranslationsQuery } from "./operations.generated"
import { Loader } from "../Loader"
import { LocalesContextProvider, LocalesContext } from "../../context"
import { translationsArrayToTranslationsRecord } from "../../utils"
import { CheckoutView, FailureStatusView, SuccessStatusView } from "./views"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { usePageTitle } from "../../hooks"
import { TITLE_CHECKOUT } from "../../constants"
import {
	CHECKOUT_FAILURE_ROUTE,
	CHECKOUT_SUCCESS_ROUTE
} from "@focus-nordic/www-common/constants"

export const CheckoutPage: React.FC = props => {
	const checkoutTranslations = useGetCheckoutTranslationsQuery()
	const localesContext = React.useContext(LocalesContext)
	const translations = checkoutTranslations.data?.checkoutTranslations
	const pageTitle = usePageTitle(TITLE_CHECKOUT)
	const { path } = useRouteMatch()

	return (
		<>
			{/* override any previous page titles */}
			<Helmet title={pageTitle} />
			{!checkoutTranslations.data ? (
				<Loader minh="100vh" />
			) : (
				<LocalesContextProvider
					{...localesContext}
					// merge checkout translations with previously fetched translations
					translations={{
						...localesContext.translations,
						...translationsArrayToTranslationsRecord(
							"checkout",
							translations ?? []
						)
					}}
				>
					<Switch>
						<Route exact path={path}>
							<CheckoutView />
						</Route>
						<Route path={CHECKOUT_FAILURE_ROUTE}>
							<FailureStatusView />
						</Route>
						<Route path={CHECKOUT_SUCCESS_ROUTE}>
							<SuccessStatusView />
						</Route>
						<Route path="*">
							<Redirect to="/" />
						</Route>
					</Switch>
				</LocalesContextProvider>
			)}
		</>
	)
}
