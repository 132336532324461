import queryString from "query-string"
import TagManager from "react-gtm-module"
import deepmerge from "deepmerge"
import { API_ENDPOINT } from "@focus-nordic/www-common/constants"
import {
	FacetType,
	Facet,
	ProductsFacetsInput,
	Range,
	Selection,
	QueryProductRangeArgs,
	RangeFacetInput,
	SelectFacetInput,
	ProductsInput,
	CategoryRoot,
	CategorySelection,
	CategoryRootItem
} from "../../@types/graphql.generated"
import { SortInput } from "../../@types/graphql.generated"
import { ProductRangeProps } from "./ProductRange"
import { FacetsState } from "./hooks"

export const productRangeArgs = (
	productRangeArgs: QueryProductRangeArgs,
	productRange: ProductRangeProps["productRange"]
): {
	pagination: QueryProductRangeArgs
	facet: QueryProductRangeArgs
} => {
	// special case for brand pages
	// category id should be omitted in favour of brandId, if non has been selected in the brand categoies list/filter
	const omitCategoryId = Boolean(
		!productRangeArgs.productsInput?.categoryId && productRange?.brandId
	)
	const commonInputs: QueryProductRangeArgs = {
		productsInput: {
			...(productRange?.brandId && {
				brandId: productRange.brandId
			}),
			...(productRange?.campaignId && {
				campaignListId: productRange.campaignId
			}),
			...(productRange?.categoryId &&
				!omitCategoryId && {
					categoryId: productRange.categoryId
				})
		},
		...(productRange?.currentSort.value && {
			sortInput: {
				sortValue: productRange.currentSort.value
			}
		})
	}

	return {
		pagination: {
			...deepmerge(commonInputs, productRangeArgs),
			paginationInput: {
				...(productRange?.pagination && {
					skip: productRange.pagination.take + productRange.pagination.skip,
					take: productRange.pagination.take
				})
			}
		},
		facet: {
			...deepmerge(commonInputs, productRangeArgs)
		}
	}
}

export const facetsToProcutsFacetsInput = (
	facets: Facet[]
): ProductsFacetsInput =>
	facets.reduce<ProductsFacetsInput>(
		(acc, facet) => ({
			...acc,
			...{
				[FacetType.Range]: {
					rangeFacetsInput: [
						...(acc.rangeFacetsInput ? acc.rangeFacetsInput : []),
						{
							key: facet.key,
							min: (facet as Range).min,
							max: (facet as Range).max
						}
					]
				},
				[FacetType.Selection]: [
					{
						...(acc.selectFacetsInput && acc.selectFacetsInput),
						selectFacetsInput: {
							key: facet.key,
							values: (facet as Selection).values
						}
					}
				],
				[FacetType.Category]: [
					{
						...(acc.selectFacetsInput && acc.selectFacetsInput),
						selectFacetsInput: {
							key: facet.key,
							values: (facet as CategoryRoot).values
						}
					}
				]
			}[facet.type]
		}),
		{}
	)

export interface NormalizedFacets {
	[id: string]: {
		type: FacetType
		values: Array<string>
		key: string
		label: string
	}
}

export const facetsStateToFacetsInput = (
	facetsState: FacetsState
): ProductsFacetsInput => {
	return Object.keys(facetsState).reduce<ProductsFacetsInput>((acc, key) => {
		const targetFacet = facetsState[key]
		// check for existing selectFacetInput
		const selectFacetInput = acc.selectFacetsInput?.find(
			facet => facet.key === targetFacet.key
		)
		const omittedSelectInputFacet =
			acc.selectFacetsInput?.filter(facet => facet.key !== targetFacet.key) ||
			[]

		const omittedRangeInputFacet =
			acc.rangeFacetsInput?.filter(facet => facet.key !== targetFacet.key) || []

		return {
			...acc,
			...{
				[FacetType.Range]: () => {
					const [min, max] = targetFacet.value.split("-")
					return {
						rangeFacetsInput: [
							// add previous result
							...omittedRangeInputFacet,
							{
								key: targetFacet.key,
								min,
								max
							}
						]
					}
				},
				[FacetType.Selection]: () => ({
					selectFacetsInput: [
						// add previous result
						...omittedSelectInputFacet,
						...(selectFacetInput
							? // add to existing facet
							  [
									{
										key: selectFacetInput.key,
										values: [...selectFacetInput.values, targetFacet.value]
									}
							  ]
							: // create new faceet
							  [
									{
										key: targetFacet.key,
										values: [targetFacet.value]
									}
							  ])
					]
				}),
				[FacetType.Category]: () => ({
					categoryFacetsInput: {
						key: targetFacet.key
					}
				})
			}[targetFacet.type]()
		}
	}, {})
}

interface ProductQueryStringObject {
	[key: string]: string[] | string
}

const productQueryStringPrefix = "productsInput-"
const rangeQueryStringPrefix = "range-"
const selectionQueryStringPrefix = "selection-"

export const QueryProductRangeArgsToProductQueryString = (
	queryProductRangeArgs: QueryProductRangeArgs
) =>
	queryString.stringify(
		Object.keys(queryProductRangeArgs).reduce<ProductQueryStringObject>(
			(acc, key) => ({
				...acc,
				...{
					sortInput: () => ({
						sort: queryProductRangeArgs.sortInput?.sortValue
					}),
					searchInput: () => ({
						q: queryProductRangeArgs.searchInput?.searchTerm
					}),
					facetsInput: () => {
						return Object.keys(queryProductRangeArgs.facetsInput!).reduce(
							(acc, key) => ({
								...acc,
								...{
									rangeFacetsInput: () =>
										queryProductRangeArgs.facetsInput?.rangeFacetsInput?.reduce(
											(acc, facet: RangeFacetInput) => ({
												...acc,
												[`${rangeQueryStringPrefix}${facet.key}`]: `${facet.min}-${facet.max}`
											}),
											{}
										),
									selectFacetsInput: () =>
										queryProductRangeArgs.facetsInput?.selectFacetsInput?.reduce(
											(acc, facet: SelectFacetInput) => ({
												...acc,
												[`${selectionQueryStringPrefix}${facet.key}`]: facet.values
											}),
											{}
										),
									categoryFacetsInput: () => ({
										[FacetType.Category.toLowerCase()]: queryProductRangeArgs.facetsInput?.categoryFacetsInput?.key
									}),
								}[key as keyof ProductsFacetsInput]()
							}),
							{}
						)
					},
					productsInput: () => queryProductRangeArgs.productsInput,
					paginationInput: () => queryProductRangeArgs.paginationInput
				}[key as keyof QueryProductRangeArgs]()
			}),
			{}
		),
		{ arrayFormat: "separator", arrayFormatSeparator: "|" }
	)

export const productQueryStringToFacetState = (
	productQueryString: string
): FacetsState => {
	let productQueryStringObject: ProductQueryStringObject = {}
	try {
		const queryStringWithPlaceholder = decodeURIComponent(productQueryString.replace(/%26/g, '__AMPERSAND__'));

		productQueryStringObject = queryString.parse(queryStringWithPlaceholder, {
			arrayFormat: "separator",
            arrayFormatSeparator: "|"
		}) as ProductQueryStringObject;
	
		// Replace the placeholder with ampersands in the final result
		for (const key in productQueryStringObject) {
			const value = productQueryStringObject[key];
	
			if (Array.isArray(value)) {
				productQueryStringObject[key] = value.map(val => val.replace(/__AMPERSAND__/g, '&'));
			} else if (typeof value === 'string') {
				productQueryStringObject[key] = value.replace(/__AMPERSAND__/g, '&');
			}
		}
	} catch {
		productQueryStringObject = {};
	}

	const parseSelectionValue = (key: string): FacetsState => {
		const facetKey = key.split(selectionQueryStringPrefix)[1]
		const facetValue = (Array.isArray(productQueryStringObject[key])
			? productQueryStringObject[key]
			: [productQueryStringObject[key]]) as string[]

		return facetValue.reduce<FacetsState>(
			(acc, value) => ({
				...acc,
				[`${facetKey}-${value}`]: {
					type: FacetType.Selection,
					key: facetKey,
					value: value
				}
			}),
			{}
		)
	}

	const parseRangeValue = (key: string): FacetsState => {
		const facetKey = key.split(rangeQueryStringPrefix)[1]
		const facetValue = productQueryStringObject[key] as string

		return {
			[facetKey]: {
				type: FacetType.Range,
				key: facetKey,
				value: facetValue
			}
		}
	}

	const parseCategoryValue = (key: string): FacetsState => {
		const facetKey = Array.isArray(productQueryStringObject[key])
			? (productQueryStringObject[key] as string[]).join('|')
			: productQueryStringObject[key] as string

		return {
			[FacetType.Category.toLowerCase()]: {
				type: FacetType.Category,
				key: facetKey,
				value: ''
			}
		}
	}

	return Object.keys(productQueryStringObject).reduce<FacetsState>(
		(acc, key) => ({
			// selection facets
			...acc,
			...(key.indexOf(selectionQueryStringPrefix) === 0 && parseSelectionValue(key)),
			...(key.indexOf(rangeQueryStringPrefix) === 0 && parseRangeValue(key)),
			...(key === FacetType.Category.toLowerCase() && parseCategoryValue(key)),
		}),
		{}
	)
}

export const queryStringToProductRangeArgs = (
	productQueryString: string
): QueryProductRangeArgs => {
	let productQueryStringObject: ProductQueryStringObject = {}
	try {
		productQueryStringObject = queryString.parse(
			productQueryString
		) as ProductQueryStringObject
	} catch {
		productQueryStringObject = {}
	}

	const { categoryId, brandId, sort } = productQueryStringObject
	const productsInput: ProductsInput = {
		...(categoryId && {
			categoryId: categoryId as ProductsInput["categoryId"]
		}),
		...((brandId || brandId === "") && { brandId: brandId as ProductsInput["brandId"] })
	}

	const sortInput = sort && {
		sortValue: sort as SortInput["sortValue"]
	}

	return {
		productsInput,
		...(sortInput && { sortInput })
	}
}

export type ExportFormat = "xlsx" | "csv"
export type ProductExportUrls = Record<ExportFormat, string>

export const getProductExportUrls = (
	productQueryString: string,
	productRange: ProductRangeProps["productRange"]
): ProductExportUrls => {
	const productsInput = queryString.stringify({
		...(productRange?.brandId && {
			[`${productQueryStringPrefix}BrandId`]: productRange.brandId
		}),
		...(productRange?.campaignId && {
			[`${productQueryStringPrefix}CampaignListId`]: productRange.campaignId
		}),
		...(productRange?.categoryId && {
			[`${productQueryStringPrefix}CategoryId`]: productRange.categoryId
		})
	})
	const productsInputSeparator = productQueryString !== "" ? "&" : "?"

	return {
		xlsx: `/${API_ENDPOINT.priceExport}/xlsx${productQueryString}${productsInputSeparator}${productsInput}`,
		csv: `/${API_ENDPOINT.priceExport}/csv${productQueryString}${productsInputSeparator}${productsInput}`
	}
}

export const trackPriceExport = (
	fileFormat: "CSV" | "XLSX",
	product: string
) => {
	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			event: "Export price list",
			export: fileFormat,
			product
		}
	})
}

export const createFacetValueForCategorySelection = (facet: Facet, value: CategorySelection) => ({
	type: facet.type,
	key: value.key,
	value: value.label
})

export const createFacetValueForCategoryRootItem = (facet: Facet, value: CategoryRootItem) => ({
	type: facet.type,
	key: value.key,
	value: value.key.indexOf("|") !== -1 ? (value.key).substring(value.key.lastIndexOf("|") + 1) : value.key
})
