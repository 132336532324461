"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colorKeys = exports.colors = void 0;
exports.colors = {
    black: "#000000",
    white: "#FFFFFF",
    orange: "#ED8D1F",
    "green-1": "#1D945C",
    "green-2": "#15824F",
    "blue-10": "#A5BECE",
    "blue-9": "#4F95B3",
    "blue-8": "#035589",
    "blue-7": "#002B45",
    "blue-6": "#005488",
    "blue-5": "#1973E8",
    "blue-4": "#3D778F",
    "blue-3": "#4D95B2",
    "blue-2": "#B2CCD3",
    "blue-1": "#E1EAED",
    "grey-10": "#F5F7F7",
    "grey-9": "#66696A",
    "grey-8": "#1D1D1B",
    "grey-7": "#F7F9FA",
    "grey-6": "#272727",
    "grey-5": "#4B4B4E",
    "grey-4": "#646768",
    "grey-3": "#C6C6C6",
    "grey-2": "#E5E5E5",
    "grey-1": "#EDEDED",
    "red-2": "#E45E5F",
    "red-1": "#DA5959",
    "yellow-1": "#FBF2E4"
};
exports.colorKeys = Object.keys(exports.colors);
