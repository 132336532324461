import React from "react"
import { BrowserRouter } from "react-router-dom"
import {
	ApolloClient,
	createHttpLink,
	ApolloProvider,
	ApolloLink
} from "@apollo/client"
import env from "@beam-australia/react-env"
import { config, Layout } from "@focus-nordic/www-common"
import { authOperationsLink } from "@focus-nordic/www-common/links"
import { refreshTokenLink, persistBtcCartLink } from "./links"
import { AppContextProvider } from "./context"
import {
	getTokensFromCookies,
	removeTokensFromCookies,
	updateTokenCookies,
	getCustomerNumberFromCookie,
} from "./utils"
import { accessTokenLink } from "@focus-nordic/www-common/links"
import { idbConnect } from "./indexedDb"
import { FeatureFlagsProviderProps } from "./context/FeatureFlagsContext"

const localeCode = window.__HEADERS__.xSite
const features: FeatureFlagsProviderProps = window.__FEATURES__ ?? {}

const customerNumber = getCustomerNumberFromCookie(window.location.hostname)()

const httpLink = createHttpLink({
	uri: env("GRAPHQL_ENDPOINT"),
	credentials: config.apolloClient.credentials,
	headers: {
		"x-site": localeCode,
		...(customerNumber && { "x-customer": customerNumber })
	}
})

// init indexedDb
idbConnect()

window[`ga-disable-${env("TAG_MANAGER_PROPERTY_ID")}`] = true

const client = new ApolloClient({
	cache: config.apolloClient.cache().restore(window.__APOLLO_STATE__),
	link: ApolloLink.from([
		persistBtcCartLink,
		refreshTokenLink({
			getTokensFromCookies,
			removeTokensFromCookies,
			updateTokenCookies
		}),
		accessTokenLink({
			getTokensFromCookies
		}),
		authOperationsLink,
		httpLink
	])
})

const App: React.FC = () => (
	<BrowserRouter>
		<ApolloProvider client={client}>
			<AppContextProvider
				featureFlagsProviderProps={features}
				localesContextProviderProps={{ localeCode }}
			>
				<Layout />
			</AppContextProvider>
		</ApolloProvider>
	</BrowserRouter>
)

export default App
