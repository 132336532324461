import React from "react"
import {
	Flex,
	ShowMore,
	Collapse,
	Checkbox,
	RangeSlider,
	RangeSliderValue,
	Text,
	Button,
} from "@focus-nordic/ui-components"
import {
	Facet,
	FacetType,
	Selection,
	Range,
	CategoryRoot,
	CategorySelection,
	CategoryRootItem,
} from "../../@types/graphql.generated"
import { FacetValue, FacetsState, getFacetStateKey } from "./hooks"
import { translationNamespace, useLocales } from "../../hooks"
import { createFacetValueForCategoryRootItem, createFacetValueForCategorySelection } from "./utils"

export interface FacetsProps {
	facets: Facet[]
	facetsState: FacetsState
	onAddFacet: (facetValue: FacetValue) => any
	onRemoveFacet: (facetValue: FacetValue) => any
	onClick?: (facetValue: FacetValue) => void
	disabled?: boolean
	brandId?: string
	productCategoryId?: string
	onBrandUnselect?: () => void
}

interface FacetSelectionProps extends Omit<FacetsProps, "facets"> {
	facet: Selection
}

const FacetSelection: React.FC<FacetSelectionProps> = props => {
	const onChange = (facetSelected: boolean, facetValue: FacetValue, hasBrandId?: Boolean) => {
		if (hasBrandId && props.onBrandUnselect) {
			props.onBrandUnselect()
		} else {
			const action = facetSelected ? props.onRemoveFacet : props.onAddFacet
			action(facetValue)
		}
	}
	const hasBrandId = Boolean(props.brandId)

	return (
		<Flex pt={1} pb={2} flexDirection="column">
			{props.facet.values.map(value => {
				const facetValue: FacetValue = {
					type: FacetType.Selection,
					key: props.facet.key,
					value: value.value
				}
				const facetSelected =
					typeof props.facetsState[getFacetStateKey(facetValue)] !== "undefined"
				return (
					<Flex
						key={`${props.facet.key}-${value.value}`}
						flexDirection="column"
					>
						<Flex py={1.5}>
							{props.facet.isBrandFacet && hasBrandId ? (
								<Checkbox
									checked={hasBrandId}
									onChange={() => onChange(facetSelected, facetValue, hasBrandId)}
									label={value.value}
									count={String(value.count)}
									disabled={props.disabled}
								/>
							) : 
								<Checkbox
									checked={facetSelected}
									onChange={() => onChange(facetSelected, facetValue)}
									label={value.value}
									count={String(value.count)}
									disabled={props.disabled}
								/>
							}
						</Flex>
					</Flex>
				)
			})}
		</Flex>
	)
}

interface FacetRangeProps extends Omit<FacetsProps, "facets"> {
	facet: Range
}

const FacetRange: React.FC<FacetRangeProps> = props => {
	const selectedFacet = props.facetsState[props.facet.key]
	const minValue = Math.floor(props.facet.min)
	const maxValue = Math.ceil(props.facet.max)
	const isFacetSelected = typeof selectedFacet !== "undefined"
	let initialRangeSliderValue: RangeSliderValue = [minValue, maxValue]
	if (isFacetSelected) {
		try {
			const [initialMin, initialMax] = selectedFacet.value.split("-")
			initialRangeSliderValue = [Number(initialMin), Number(initialMax)]
		} catch {}
	}

	const updateFacets = (range: RangeSliderValue) => {
		const [min, max] = range
		const facetValue: FacetValue = {
			type: props.facet.type,
			key: props.facet.key,
			value: `${min}-${max}`
		}
		if (min !== minValue || max !== maxValue) {
			props.onAddFacet(facetValue)
		} else {
			props.onRemoveFacet(facetValue)
		}
	}

	return (
		<Flex pb={4} w={1} flex={1}>
			<RangeSlider
				// this key will help to rerender RangeSlider and reset it's inital value when its facet state value changes'
				key={`${props.facet.key}-${isFacetSelected}`}
				min={minValue}
				max={maxValue}
				initialValue={initialRangeSliderValue}
				disabled={props.disabled ?? false}
				onChange={updateFacets}
				range={[props.facet.min, props.facet.max]}
				hasInputControlls
			/>
		</Flex>
	)
}

export interface FacetCategoryProps extends Omit<FacetsProps, "facets"> {
	facet: CategoryRoot
}

export const FacetCategory: React.FC<FacetCategoryProps> = ({ facet, onClick, facetsState }) => {
	const isCategoryRootItem = facet.values.some(value => value.__typename === "categoryRootItem")

	const renderCategoryItems = (values: CategorySelection[]) => (
		values.map((value, index) => {
			const categoryKey = FacetType.Category.toLowerCase()
			const facetValue = createFacetValueForCategorySelection(facet, value)

			const facetSelected = Object.keys(facetsState).length !== 0 && facetsState.category
				? facetsState[categoryKey].key.toLowerCase() === getFacetStateKey(facetValue).toLowerCase() 
				: index === 0

			return (
				<Flex py={1.5} alignItems="baseline" key={value.key}>
					<Button
						variant="transparent"
						fontSize={1.75}
						lineHeight={2}
						textColor={facetSelected ? "black" : "grey-4"}
						fontWeight={facetSelected ? "bold" : "normal"}
						p={0}
						onClick={() => onClick?.(facetValue)}
					>
						{value.label}
						<Text
							as="span"
							mb={0}
							ml={0.75}
							fontSize={1.75}
							lineHeight={2}
							textColor={facetSelected ? "grey-4" : "grey-3"}
						>
						{value.count}
						</Text>
					</Button>
				</Flex>
			)
		})
	)

	return (
		<Flex pt={1} pb={2} flexDirection="column">
			{isCategoryRootItem ?
				(facet.values as CategoryRootItem[]).map(value => {
					const facetValue = createFacetValueForCategoryRootItem(facet, value)

					return (
						<Flex flexDirection="column" key={value.key}>
							<Flex>
								<Button
									variant="transparentBlack"
									icon="arrow-left-big"
									fontSize={1.75}
									lineHeight={2}
									fontWeight="bold"
									px={0}
									onClick={() => onClick?.(facetValue)}
								>
									{value.label}
								</Button>
							</Flex>
							<Flex flexDirection="column" pl={3.5}>
								{renderCategoryItems(value.values)}
							</Flex>
						</Flex>
					)
				})
				: renderCategoryItems(facet.values as CategorySelection[])
			}
		</Flex>
	)
}

export const Facets: React.FC<FacetsProps> = props => {
	const locales = useLocales(translationNamespace.shared("Common"), [
		"showMore"
	])

	return (
		<>
			{props.facets.map((facet) => (
				// TODO: remove this condition when Black Friday campaign ends (productCategoryId 658), it's a hardcode qiuck-fix
				facet?.__typename === "categoryRoot" && props.productCategoryId === "658" ? 
					null : 
					<Flex key={facet.key} borderBottom flexDirection="column" display={{ _: facet.type === FacetType.Category ? "none" : "flex", xl: "flex"}}>
						<Collapse
							titleComponent={
								<Collapse.Title title={facet.label} px={{ _: 2, xl: 0 }} />
							}
							expandedByDefault={facet.type === FacetType.Category || (facet.type === FacetType.Selection && (facet as Selection).isBrandFacet)}
						>
							<Flex px={{ _: 2, xl: 0 }} flexDirection="column" overflow="hidden">
								<ShowMore 
									cta={locales.showMore}
									height={35}
									isShowAllByDefault={facet.type === FacetType.Category}
								>
									{{
										[FacetType.Range]: () => (
											<FacetRange
												facet={facet as Range}
												facetsState={props.facetsState}
												onAddFacet={props.onAddFacet}
												onRemoveFacet={props.onRemoveFacet}
												disabled={props.disabled}
											/>
										),
										[FacetType.Selection]: () => (
											<FacetSelection
												facet={facet as Selection}
												facetsState={props.facetsState}
												onAddFacet={props.onAddFacet}
												onRemoveFacet={props.onRemoveFacet}
												disabled={props.disabled}
												brandId={props.brandId}
												onBrandUnselect={props.onBrandUnselect}
											/>
										),
										[FacetType.Category]: () => (
											<FacetCategory
												facet={facet as CategoryRoot}
												facetsState={props.facetsState}
												onAddFacet={props.onAddFacet}
												onRemoveFacet={props.onRemoveFacet}
												disabled={props.disabled}
												onClick={props.onClick}
											/>
										)
									}[facet.type]()}
								</ShowMore>
							</Flex>
						</Collapse>
				</Flex>
			))}
		</>
	)
}
Facets.defaultProps = {
	disabled: false
}
