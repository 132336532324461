import React from "react"
import { CommonProductFieldsFragment } from "../../operations/product.generated"
import {
	CartSummary,
	Cart as ICart,
	CustomerTypeEnum
} from "../../@types/graphql.generated"

export type Cart = {
	currency: CartSummary["currency"]
	items?: CartSummary["items"]
	vatRate?: CartSummary["vatRate"]
	itemsPriceSum?: CartSummary["itemsPriceSum"]
	freightCost?: ICart["freightCost"]
	fullOrderDiscountAmount?: CartSummary["fullOrderDiscountAmount"]
	fullOrderDiscountApplied?: CartSummary["fullOrderDiscountApplied"]
}

export type UpdateCartItemInput = {
	product: CommonProductFieldsFragment
	quantity?: number
}

export type UpdateCartItem = (updateCartItemInput: UpdateCartItemInput) => any

export type UpdateCartItemCustomPriceInput = {
	product: CommonProductFieldsFragment
	customPrice?: number
}

export type UpdateCartItemCustomPrice = (
	updateCartItemCustomPriceInput: UpdateCartItemCustomPriceInput
) => any

export interface CartContextProviderProps {
	cart: Cart
	updateCartItem: UpdateCartItem
	updateCartItemCustomPrice?: UpdateCartItemCustomPrice
	checkItemsAvailability?: () => void
	clearCart?: () => void
	initCart: (customerType: CustomerTypeEnum, dicountCode?: string) => void
	loading: boolean
	isInitialized: boolean
	updateCartWithDiscountCode?: (customerType: CustomerTypeEnum, dicountCode: string) => void
	discountCodeValue?: string
}
export interface CartContextValue extends CartContextProviderProps {}

export const clientCartDefault = {
	currency: ""
}

export const CartContext = React.createContext<CartContextValue>({
	cart: clientCartDefault,
	updateCartItem: () => {},
	checkItemsAvailability: () => {},
	updateCartItemCustomPrice: () => {},
	initCart: () => {},
	loading: false,
	isInitialized: false,
	updateCartWithDiscountCode: () => {}
})
